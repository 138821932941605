<template>
  <div class="details-steps">
    <v-tabs v-model="selectedDay" centered class="mb-5">
      <v-tabs-slider color="secondary"></v-tabs-slider>

      <v-tab
        v-for="(schedule, i) in item.data.schedules"
        :key="i"
        @click="selectDayHandler(schedule)"
      >
        Day {{ schedule.day.dayNumber }}
      </v-tab>
    </v-tabs>
    <div class="mb-5">
      <GmapMap
        v-if="selectedDayObject.schedule[0]"
        :center="{
          lat: Number(selectedDayObject.schedule[0].latitude),
          lng: Number(selectedDayObject.schedule[0].longitude)
        }"
        :zoom="10"
        style="width: 100%; height: 300px"
      >
        <GmapMarker
          :key="index"
          v-for="(schedule, index) in selectedDayObject.schedule"
          :position="{
            lat: Number(schedule.latitude),
            lng: Number(schedule.longitude)
          }"
        />
      </GmapMap>
    </div>

    <div class="steps">
      <div class="step-line"></div>
      <div class="mb-8">
        <div v-for="(schedule, j) in selectedDayObject.schedule" :key="j">
          <div class="w-100">
            <div class="d-flex align-center justify-space-between">
              <div class="d-flex align-center justify-space-between">
                <div class="step-min b-cover px-2 py-1 radius-20 mr-2">
                  <v-icon color="secondary"
                    >mdi-{{ transportIcon(schedule.transport) }}</v-icon
                  >
                </div>
                <span class="secondary--text font-12 font-500">{{
                  schedule.description[$root.language]
                }}</span>
              </div>
            </div>

            <div class="pl-7 mt-2">
              <span class="font-14 font-500 d-block black--text"
                >At {{ schedule.startTime }} for {{ schedule.duration }}
                {{ schedule.duration == 1 ? "hour" : "hours" }}</span
              >
              <p v-if="schedule.free" class="font-14 white-grey--text">
                This step is free
              </p>
              <span v-if="!schedule.free">
                <p class="font-14 white-grey--text">This step is not free</p>
                <p
                  v-if="!schedule.priceExcluded"
                  class="font-14 white-grey--text"
                >
                  And the prices are excluded
                </p>
                <p
                  v-if="schedule.priceExcluded"
                  class="font-14 white-grey--text"
                >
                  But the prices are included
                </p>
              </span>

              <p
                @click="goToAttraction(schedule.attraction)"
                class="blue--text font-14 cursor-pointer"
              >
                Go to Attraction ({{ schedule.attraction.name[$root.language] }})
              </p>
            </div>
          </div>

          <div
            class="photos-section"
            v-if="schedule.image && schedule.image.length > 0"
          >
            <div class="photo mb-2 ml-5" style="height: 120px; width: 100px">
              <div
                v-if="schedule.image.length > 1"
                class="photo-count cursor-pointer"
                @click="photosDialogHandler(schedule.image)"
              >
                <span class="white--text font-26"
                  >+{{ schedule.image.length }}</span
                >
              </div>
              <img
                v-for="image in schedule.image"
                :key="image._id"
                :src="image.url || (image.mediaId && image.mediaId.url)"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="photosDialog"
      fullscreen
      hide-overlay
      transition="scale-transition"
      content-class="full-dialog"
    >
      <dialog-photos
        :key="photosDialog"
        :images="images"
        type="experience"
        @close="photosDialog = false"
      ></dialog-photos>
    </v-dialog>
  </div>
</template>

<script>
import DialogPhotos from "@/components/dialogs/Photos";

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    DialogPhotos
  },
  data: () => ({
    photosDialog: false,
    selectedDay: "",
    images: [],
    selectedDayObject: {}
  }),
  methods: {
    goToAttraction(attraction) {
      this.$router.push(`/attractions/${attraction._id}`);
    },
    photosDialogHandler(images) {
      this.photosDialog = true;
      this.images = images;
    },
    selectDayHandler(schedule) {
      this.selectedDayObject = schedule.day;
    },
    transportIcon(transport) {
      if (transport == "drive") {
        return "car";
      }
      if (transport == "walk") {
        return "walk";
      }
      if (transport == "swim") {
        return "swim";
      }
      if (transport == "eat") {
        return "walk";
      } else {
        return "blabla";
      }
    }
  },
  created() {
    this.selectedDayObject = this.item.data.schedules[0].day;
  }
};
</script>

<style lang="scss">
.details-steps {
  .steps {
    position: relative;

    .step-line {
      content: "";
      position: absolute;
      left: 15px;
      top: 10px;
      height: calc(100%);
      width: 2px;
      background: $grayicon;
    }

    .step-min {
      position: relative;
      z-index: 9;
      background: white;
    }
  }
}
</style>
