<template>
  <div class="guesthouses-body py-5" v-if="item">
    <div class="pb-2 d-flex" v-if="!viewOnly">
      <v-icon
        size="40"
        class="cursor-pointer ml-auto"
        @click="collapse"
        color="black"
        >mdi-progress-close</v-icon
      >
    </div>
    <div class="guesthouses-body-content">
      <div class="main-item pt-3" v-if="!viewOnly">
        <div class="main-img">
          <img
            v-if="item.data && item.data.attraction.coverImage"
            width="100%"
            height="100%"
            class="object-cover cursor-pointer"
            :src="item.data.attraction.coverImage.url"
            @click="coverPhotosDialogHandler"
          />
        </div>
      </div>

      <v-row>
        <v-col cols="12" :md="12" :key="item.data._id">
          <details-text
            :item="item.data"
            @editHandler="$emit('editGuesthouse')"
            :actions="actions"
            @reloadData="$emit('reloadData')"
            :viewOnly="viewOnly"
            @collapse="collapse"
            @restoreHandler="$emit('restoreHandler', item.data)"
          />
          <details-steps :item="item" />
          <details-images v-if="item" :item="item"></details-images>
          <details-what-to :item="item"></details-what-to>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="coverPhotosDialog"
      fullscreen
      hide-overlay
      transition="scale-transition"
      content-class="full-dialog"
    >
      <dialog-photos
        :key="coverPhotosDialog"
        :images="images"
        @close="coverPhotosDialog = false"
      ></dialog-photos>
    </v-dialog>
  </div>
  <div v-else style="height: 100vh" class="pt-5">
    <v-skeleton-loader type="card"></v-skeleton-loader>
    <v-col cols="12" md="12" v-for="(item, i) in [1, 2, 3, 4, 5, 6]" :key="i">
      <v-skeleton-loader type="text"></v-skeleton-loader>
    </v-col>
  </div>
</template>

<script>
import DetailsText from "./details/Text.vue";
import DetailsImages from "./details/Images.vue";
import DetailsSteps from "./details/Steps.vue";
import DetailsWhatTo from "./details/WhatTo.vue";
import DialogPhotos from "@/components/dialogs/Photos";

export default {
  props: {
    item: {
      type: [Object],
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => ["hide", "delete", "edit"]
    }
  },
  components: {
    DialogPhotos,
    DetailsText,
    DetailsImages,
    DetailsSteps,
    DetailsWhatTo
  },
  data: () => ({
    photosDialog: false,
    coverPhotosDialog: false,
    images: [],
    bookings: []
  }),
  methods: {
    photosDialogHandler() {
      this.images = this.itemID.media;
      this.photosDialog = true;
    },
    coverPhotosDialogHandler() {
      this.images = { url: this.item.data.attraction.coverImage };
      this.coverPhotosDialog = true;
    },
    collapse() {
      this.$emit("collapse");
    }
  }
};
</script>

<style lang="scss" scoped>
.guesthouses-body {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .guesthouses-body-content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    .main-item {
      .main-img {
        border: 1px solid #eaedf3;
        height: 300px;
        border-radius: 5px;
        overflow: hidden;
        img {
          overflow: hidden;
        }
      }
    }
  }
}
</style>
